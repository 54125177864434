export const menuItems = [
  {
    id: 1,
    label: 'Employees',
    link: "/employees",
    icon: "mdi mdi-account-multiple",
    acl: ['all']
  },
  {
    id: 9,
    label: 'Emails',
    link: "/emails",
    icon: "bxs-user-detail",
    acl: ['all']
  },
  {
    id: 10,
    label: 'Services',
    link: "/services",
    icon: "mdi mdi-puzzle",
    acl: ['all']
  },
  {
    id: 12,
    label: 'Notification',
    link: "/notifications",
    icon: "bxs-box",
    acl: ['all']
  },
  {
    id: 24,
    label: 'Settings',
    icon: "bx bx-copyright",
    acl: ['admin'],
    subItems: [
      {
        id: 241,
        label: 'Console Commands',
        link: '/settings/console-commands',
        parentId: 24
      }
    ]
  },
]
