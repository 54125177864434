<script>
import MetisMenu from "metismenujs/dist/metismenujs";

import {
  menuItems
} from "./menu";

/**
 * Sidenav component
 */
export default {
  data() {
    return {
      menuItems: menuItems,
      menuData: null,
      showLogout: false,
      url: process.env.VUE_APP_AUTH_REDIRECT_LINK,
    };
  },
  computed: {
    user() {
      return this.$store.state.user.user;
    }
  },
  mounted: function () {

    // eslint-disable-next-line no-unused-vars
    var menuRef = new MetisMenu("#side-menu");
    var links = document.getElementsByClassName("side-nav-link-ref");
    var matchingMenuItem = null;
    const paths = [];

    for (var i = 0; i < links.length; i++) {
      paths.push(links[i]["pathname"]);
    }
    var itemIndex = paths.indexOf(window.location.pathname);
    if (itemIndex === -1) {
      const strIndex = window.location.pathname.lastIndexOf("/");
      const item = window.location.pathname.substr(0, strIndex).toString();
      matchingMenuItem = links[paths.indexOf(item)];
    } else {
      matchingMenuItem = links[itemIndex];
    }

    if (matchingMenuItem) {
      matchingMenuItem.classList.add("active");
      var parent = matchingMenuItem.parentElement;

      /**
       * TODO: This is hard coded way of expading/activating parent menu dropdown and working till level 3.
       * We should come up with non hard coded approach
       */
      if (parent) {
        parent.classList.add("mm-active");
        const parent2 = parent.parentElement.closest("ul");
        if (parent2 && parent2.id !== "side-menu") {
          parent2.classList.add("mm-show");

          const parent3 = parent2.parentElement;
          if (parent3) {
            parent3.classList.add("mm-active");
            var childAnchor = parent3.querySelector(".has-arrow");
            var childDropdown = parent3.querySelector(".has-dropdown");
            if (childAnchor) childAnchor.classList.add("mm-active");
            if (childDropdown) childDropdown.classList.add("mm-active");

            const parent4 = parent3.parentElement;
            if (parent4 && parent4.id !== "side-menu") {
              parent4.classList.add("mm-show");
              const parent5 = parent4.parentElement;
              if (parent5 && parent5.id !== "side-menu") {
                parent5.classList.add("mm-active");
                const childanchor = parent5.querySelector(".is-parent");
                if (childanchor && parent5.id !== "side-menu") {
                  childanchor.classList.add("mm-active");
                }
              }
            }
          }
        }
      }
    }
  },
  methods: {
    /**
     * Returns true or false if given menu item has child or not
     * @param item menuItem
     */
    hasItems(item) {
      return item.subItems !== undefined ? item.subItems.length > 0 : false;
    },

    toggleMenu(event) {
      event.currentTarget.nextElementSibling.classList.toggle("mm-show");
    },

    enableMenuItem(menuItem){
      return process.env?.VUE_APP_DISPLAY_ALL === 'true' || menuItem?.acl.includes('all') || menuItem?.acl.includes(this.user.acl);
    },
  },
};
</script>

<template>
  <!-- ========== Left Sidebar Start ========== -->

  <!--- Sidemenu -->
  <div id="sidebar-menu" class="sidebar-menu">
    <div>
      <div class="navbar-brand-box">
        <router-link tag="a" to="/" class="logo logo-light">
                <span class="logo-sm">
                    <img src="@/assets/images/bimi-logo-white.png" alt height="19" />
                </span>
          <span class="logo-lg">
                    <img src="@/assets/images/bimi-logo-white.png" alt height="40" />
                </span>
        </router-link>
      </div>
      <!-- Left Menu Start -->
      <ul id="side-menu" class="metismenu list-unstyled">
        <template v-for="item in menuItems">
          <li class="menu-title" v-if="item.isTitle && enableMenuItem(item)" :key="item.id">
            {{ item.label }}
          </li>
          <li v-if="!item.isTitle && !item.isLayout && enableMenuItem(item)" :key="item.id">
            <a v-if="hasItems(item)" href="javascript:void(0);" class="is-parent" :class="{ 'has-arrow': !item.badge, 'has-dropdown': item.badge }">
              <i :class="`bx ${item.icon}`" v-if="item.icon"></i>
              <span>{{ item.label }}</span>
              <span :class="`badge badge-pill badge-${item.badge.variant} float-right`" v-if="item.badge">{{ item.badge.text }}</span>
            </a>

            <router-link :to="item.link" v-if="!hasItems(item)" class="side-nav-link-ref">
              <i :class="`bx ${item.icon}`" v-if="item.icon"></i>
              <span>{{ item.label }}</span>
              <span :class="`badge badge-pill badge-${item.badge.variant} float-right`" v-if="item.badge">{{ item.badge.text }}</span>
            </router-link>

            <ul v-if="hasItems(item)" class="sub-menu" aria-expanded="false">
              <li v-for="(subitem, index) of item.subItems" :key="index">
                <router-link :to="subitem.link" v-if="!hasItems(subitem)" class="side-nav-link-ref">{{ subitem.label }}</router-link>
                <a v-if="hasItems(subitem)" class="side-nav-link-a-ref has-arrow" href="javascript:void(0);">{{ subitem.label }}</a>
                <ul v-if="hasItems(subitem)" class="sub-menu mm-collapse" aria-expanded="false">
                  <li v-for="(subSubitem, index) of subitem.subItems" :key="index">
                    <router-link :to="subSubitem.link" class="side-nav-link-ref">{{ subSubitem.label }}</router-link>
                  </li>
                </ul>
              </li>
            </ul>
          </li>
        </template>
      </ul>
    </div>
    <div class="bottom-menu">
      <div class="bottom-menu__user" :class="{'bottom-menu__user_active': showLogout}">
        <div v-if="user" class="user">
          <a :href="url + 'profile'" class="avatar-title rounded-circle">
            <i
              v-if="!user.photo"
              class="bx bx-user font-size-24"
            ></i>
            <img
              v-else
              :src="user.photo"
              class="cover-parent"
            />
          </a>
        </div>
        <p class="bottom-menu__user-name">
          <span @click="showLogout=true">{{ user.name }}</span>
          <span @click="showLogout=true"><i class="fas fa-angle-right"></i></span>
          <a href="#" v-show="showLogout" class="bottom-menu__logout" @click.prevent="$keycloak.logout">
            Logout
            <i class="bx bx-power-off font-size-16 align-middle mr-1 text-danger" />
          </a>
          <span v-show="showLogout" @click="showLogout=false" class="bottom-menu__user-close"></span>
        </p>
      </div>
      <p class="bottom-menu__copy">2022 &copy; Bimiboo</p>
    </div>

  </div>
  <!-- Sidebar -->
</template>

<style scoped>
.sidebar-menu {
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  height: 100vh;
  box-sizing: border-box;
}
.sidebar-menu::-webkit-scrollbar {
  width: 0;
}
.bottom-menu {
  text-align: center;
}
.bottom-menu__user {
  background-color: #252936;
  text-align: center;
  padding: 20px 0;
  transition: background-color 0.2s ease-out;
}
.bottom-menu__user_active {
  background-color: #353d52;
}
.user {
  margin: auto;
  width: 48px;
  height: 48px;
  border-radius: 50% 50%;
  overflow: hidden;
}
.bottom-menu__user-name {
  color: #ffffff;
  font-size: 14px;
  margin-top: 4px;
  margin-bottom: 0;
  position: relative;
  cursor: pointer;
}
.bottom-menu__user-name span i {
  font-weight: 600;
  padding-left: 10px;
}
.bottom-menu__copy {
  margin: 20px 0 10px;
  font-size: 12px;
  color: #676e84;
}
.bottom-menu__logout {
  position: fixed;
  width: 120px;
  background-color: #353d52;
  color: #ffffff;
  height: 54px;
  border-radius: 4px;
  text-align: center;
  line-height: 54px;
  margin: -17px 0 0 50px;
  z-index: 2;
}
.bottom-menu__user-close {
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
}
.bottom-menu__logout img {
  margin-left: 5px;
  cursor: pointer;
}
.cover-parent {
  width: 50px;
}
</style>
