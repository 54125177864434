<script>
import { layoutComputed } from "@/state/helpers";
import Vertical from "./vertical";

export default {
    props: {
      loading: {
        type: Boolean,
        default: false,
        require: false
      }
    },
    components: {
        Vertical,
    },
    data() {
        return {};
    },
    computed: {
        ...layoutComputed,
    },
    methods: {},
};
</script>

<template>
<div>
    <vertical :layout="layoutType" :loading="loading">
        <slot />
    </vertical>
</div>
</template>
