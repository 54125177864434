<template>
  <transition name="fade">
    <div v-if="show" class="loader">
      <b-spinner
        variant="primary"
        label="Loading..."
        style="width: 4rem; height: 4rem; border-width: 0.4em;"
      />
    </div>
  </transition>
</template>

<script>
export default {
  name: 'PageLoader',
  props: {
    show: {
      default: false,
      required: false
    }
  }
}
</script>

<style lang="scss" scoped>
  .loader {
    top: 0px;
    left: 0px;
    right: 0px;
    bottom: 0px;
    z-index: 1003;
    position: fixed;
    display: flex;
    align-items: center;
    justify-content: center;
    background: rgba(#fff, 0.3);
  }
  .fade-enter-active, .fade-leave-active {
    transition: opacity .5s;
  }
  .fade-enter, .fade-leave-to {
    opacity: 0;
  }
</style>